$.extend($.validator.messages, {
  required: 'Это поле обязательно для заполнения',
  remote: 'Введите правильное значение',
  email: 'Введите корректную электронную почту',
  url: 'Введите корректный URL',
  date: 'Введите корректную дату',
  dateISO: 'Введите корректную дату в формате ISO',
  number: 'Введите число',
  digits: 'Вводите только цифры',
  letters: 'Вводите только буквы',
  creditcard: 'Введите правильный номер кредитной карты',
  equalTo: 'Пароли не совпадают',
  extension: 'Выберите файл с правильным расширением',
  maxlength: $.validator.format('Максимальная длина описания - {0} символов'),
  minlength: $.validator.format('Введите не меньше {0} символов'),
  rangelength: $.validator.format(
    'Введите значение длиной от {0} до {1} символов'
  ),
  range: $.validator.format('Введите число от {0} до {1}'),
  max: $.validator.format('Максимальная длина описания - {0} символов'),
  min: $.validator.format('Введите число, большее или равное {0}'),
});

$('.js-validate').each((index, form) => {
  $(form).validate({
    ignore: [':hidden'],
    errorElement: 'div',
    errorClass: 'form__error',
    errorPlacement: function (error, element) {
      error.prependTo(element.parents('.form__field'));

      if ($(element).attr('type') === 'checkbox') {
        error.appendTo(element.parents('.form__checkbox-row'));
      }
    },
    highlight(element) {
      setTimeout(() => {
        $(element).parents('.form__field').addClass('is-error');
      });
    },
    unhighlight(element) {
      setTimeout(() => {
        $(element).parents('.form__field').removeClass('is-error');
      });
    },
    submitHandler: function (form) {
      form.submit();
    },
  });
});

// eslint-disable-next-line no-useless-escape
$.validator.addMethod(
  'emailcustom',
  (value) =>
    value.match(
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9A-Яа-я_\-\.])+\.([A-Za-zA-Яа-я_]{2,6})$/
    ),
  'Введите корректный e-mail'
);

$.validator.addClassRules('js-email', {
  emailcustom: true,
});

$(document).on('input', '.js-name', (e) => {
  e.target.value = e.target.value.replace(/[^а-яА-Яa-zA-Z-\s]/g, '');
});
